<template>
    <b-list-group ref="infiniteList" id="infinite-list">
        <slot />
        <b-list-group-item v-if="loading" class="d-flex justify-content-center align-items-center">
            <b-spinner />
        </b-list-group-item>
    </b-list-group>
</template>

<script>
export default {
    props: {
        getNext: {
            type: Function,
            required: true,
        },
        per_page: {
            type: Number,
            required: true,
        },
        total_pages: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            current_page: 0,
        };
    },
    methods: {
        async callGetNext() {
            this.loading = true;
            const offset = this.per_page * this.current_page;
            await this.getNext(offset);
            this.loading = false;
            this.current_page += 1;
        },
        resetState() {
            this.loading = false;
            this.$refs.infiniteList.$el?.scrollTo({top: 0});
            this.current_page = 0;
        },
        onScroll() {
            const listEl = this.$refs.infiniteList;
            if (!this.loading && this.total_pages >= this.current_page) {
                if (listEl.scrollTop + listEl.clientHeight + 100 > listEl.scrollHeight) {
                    this.callGetNext();
                }
            }
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.$refs.infiniteList.addEventListener('scroll', this.onScroll);
        });
    },
    beforeDestroy() {
        this.$refs.infiniteList.removeEventListener('scroll', this.onScroll);
    },
    expose: ['resetState'],
};
</script>
