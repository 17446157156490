<template>
    <b-form @submit.prevent="submitForm">
        <b-row :align-v="editMode ? 'end' : 'start'">
            <b-col :md="editMode ? 6 : 12" :sm="12">
                <b-form-group
                    label-class="text-capitalize"
                    label="Email"
                    label-for="input-user-email"
                >
                    <b-input-group>
                        <b-input-group-prepend is-text>
                            <b-icon icon="at"></b-icon>
                        </b-input-group-prepend>
                        <b-form-input
                            id="input-user-email"
                            v-model="$v.form.email.$model"
                            required
                            :disabled="editMode"
                            :placeholder="t('Email')"
                            autocomplete="off"
                            :aria-invalid="$v.form.email.$error"
                            :state="saveAttempted ? (!$v.form.email.$error ? null : false) : null"
                        ></b-form-input>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col :md="editMode ? 3 : 6" sm="12">
                <b-form-group
                    label-class="text-capitalize"
                    :label="$t('firstName')"
                    label-for="input-first-name"
                >
                    <b-form-input
                        id="input-first-name"
                        v-model="$v.form.firstName.$model"
                        required
                        :placeholder="t('firstName')"
                        autocomplete="off"
                        :aria-invalid="$v.form.firstName.$error"
                        :state="saveAttempted ? (!$v.form.firstName.$error ? null : false) : null"
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col :md="editMode ? 3 : 6" sm="12">
                <b-form-group
                    label-class="text-capitalize"
                    :label="$t('lastName')"
                    label-for="input-last-name"
                >
                    <b-form-input
                        class="ml"
                        id="input-last-name"
                        v-model="$v.form.lastName.$model"
                        required
                        :placeholder="$t('lastName')"
                        :aria-invalid="$v.form.lastName.$error"
                        :state="saveAttempted ? (!$v.form.lastName.$error ? null : false) : null"
                        autocomplete="off"
                    ></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col :md="editMode ? 3 : 6" sm="6">
                <b-form-group
                    label-class="text-capitalize"
                    :label="$t('_role')"
                    label-for="input-role"
                >
                    <b-form-select
                        id="input-role"
                        :options="roles"
                        v-model="$v.form.role.$model"
                        text-field="name"
                        value-field="roleId"
                        :aria-invalid="$v.form.role.$error"
                        :state="saveAttempted ? (!$v.form.role.$error ? null : false) : null"
                    >
                        <template #first>
                            <b-form-select-option :value="null">{{
                                $t('select')
                            }}</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
            </b-col>

            <b-col :md="editMode ? 3 : 6" sm="6">
                <b-form-group
                    label-class="text-capitalize"
                    :label="$t('language')"
                    label-for="input-language"
                >
                    <b-form-select
                        id="input-language"
                        :options="mySortedLanguages"
                        v-model="$v.form.language.$model"
                        text-field="name"
                        value-field="code"
                        :aria-invalid="$v.form.language.$error"
                        :state="saveAttempted ? (!$v.form.language.$error ? null : false) : null"
                    >
                        <template #first>
                            <b-form-select-option :value="null">{{
                                $t('select')
                            }}</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
            </b-col>
            <b-col :md="editMode ? 2 : 4" sm="6">
                <b-form-group
                    label-class="text-capitalize"
                    :label="$t('bulkOrdering')"
                    label-for="input-bulk-ordering"
                >
                    <b-form-select
                        id="input-bulk-ordering"
                        :options="bulkOrderingOptions"
                        v-model="$v.form.bulkOrdering.$model"
                        text-field="label"
                        value-field="value"
                        :aria-invalid="$v.form.bulkOrdering.$error"
                        :state="
                            saveAttempted ? (!$v.form.bulkOrdering.$error ? null : false) : null
                        "
                    >
                        <template #first>
                            <b-form-select-option :value="null">{{
                                $t('select')
                            }}</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
            </b-col>
            <b-col :md="editMode ? 2 : 4" sm="6">
                <b-form-group
                    label-class="text-capitalize"
                    :label="$t('consignmentRights')"
                    label-for="input-consignment-rights"
                >
                    <b-form-select
                        id="input-consignment-rights"
                        :options="consignmentRightsOptions"
                        v-model="$v.form.consignmentRights.$model"
                        text-field="label"
                        value-field="value"
                        :aria-invalid="$v.form.consignmentRights.$error"
                        :state="
                            saveAttempted
                                ? !$v.form.consignmentRights.$error
                                    ? null
                                    : false
                                : null
                        "
                    >
                        <template #first>
                            <b-form-select-option :value="null">{{
                                $t('select')
                            }}</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
            </b-col>
            <b-col :md="editMode ? 2 : 4" sm="6">
                <b-form-group
                    label-class="text-capitalize"
                    :label="$t('decimalSeparator')"
                    label-for="input-decimal-separator"
                >
                    <b-form-select
                        id="input-decimal-separator"
                        :options="decimalSeparators"
                        v-model="$v.form.decimalSeparator.$model"
                        :aria-invalid="$v.form.decimalSeparator.$error"
                        :state="
                            saveAttempted ? (!$v.form.decimalSeparator.$error ? null : false) : null
                        "
                    >
                        <template #first>
                            <b-form-select-option :value="null">{{
                                $t('select')
                            }}</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group
                    label-class="text-capitalize"
                    :label="$t('businessUnits')"
                    label-for="input-decimal-separator"
                >
                    <b-form-checkbox-group
                        id="input-decimal-separator"
                        class="pointer"
                        :options="businessUnitsOptions"
                        v-model="$v.form.businessUnits.$model"
                        :aria-invalid="$v.form.businessUnits.$error"
                        multiple
                        plain
                        :state="
                            saveAttempted ? (!$v.form.businessUnits.$error ? null : false) : null
                        "
                    />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row v-if="editMode" align-h="end">
            <b-col md="2" sm="12">
                <div class="d-flex w-100 h-100 align-items-end">
                    <b-btn
                        id="submit-user-edit"
                        class="w-100 mb-4"
                        variant="secondary"
                        @click="resetForm"
                    >
                        {{ $t('reset') }}
                    </b-btn>
                </div>
            </b-col>
            <b-col md="2" sm="12">
                <div class="d-flex w-100 h-100 align-items-end">
                    <b-btn
                        id="submit-user-edit"
                        class="w-100 mb-4"
                        variant="primary"
                        @click="submitForm"
                    >
                        {{ $t('save') }}
                    </b-btn>
                </div>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
import {DecimalSeparators as decimalSeparators} from '@/constants/user';
import {required, email} from 'vuelidate/lib/validators';
import {mapGetters, mapState} from 'vuex';
import set from 'lodash/set.js';

let defaultFormValues = {
    firstName: '',
    lastName: '',
    email: '',
    role: null,
    bulkOrdering: null,
    consignmentRights: null,
    language: null,
    decimalSeparator: null,
    businessUnits: [],
};

export default {
    expose: ['resetForm', 'submitForm'],
    emits: ['submit'],
    props: {
        editMode: {
            type: Boolean,
            default: false,
            required: false,
        },
        value: {
            type: Object,
            default: null,
            required: false,
        },
    },
    validations: {
        form: {
            email: {
                required,
                email,
            },
            firstName: {
                required,
            },
            lastName: {
                required,
            },
            role: {
                required,
            },
            bulkOrdering: {
                required,
            },
            consignmentRights: {
                required,
            },
            language: {
                required,
            },
            decimalSeparator: {
                required,
            },
            businessUnits: {},
        },
    },
    data: () => ({
        form: {},
        decimalSeparators,
        saveAttempted: false,
        initialValues: {},
    }),
    computed: {
        ...mapGetters({
            mySortedLanguages: 'user/mySortedLanguages',
            allRoles: 'roles/list',
        }),
        ...mapState({
            businessUnits: (state) => state.user.businessUnits,
        }),
        roles() {
            return this.allRoles.map((role) => set(role, 'disabled', !role.canAssign));
        },
        consignmentRightsOptions() {
            return [
                {label: this.$t('yes'), value: true},
                {label: this.$t('no'), value: false},
            ];
        },
        bulkOrderingOptions() {
            return [
                {value: 0, label: this.$t('none_select')},
                {value: 1, label: this.$t('toric_label')},
                {value: 2, label: this.$t('spherical_label')},
                {value: 3, label: this.$t('both_select')},
            ];
        },
        businessUnitsOptions() {
            return this.businessUnits.map((businessUnit) => {
                return {
                    value: businessUnit.businessUnitId,
                    text: this.$t(businessUnit.name),
                };
            });
        },
    },
    methods: {
        resetForm() {
            if (this.editMode) {
                this.form = {...this.initialValues};
            } else {
                this.form = {...defaultFormValues};
            }
            this.$v.$reset();
        },
        submitForm() {
            this.saveAttempted = true;
            this.$v.form.$touch();
            if (this.$v.$error) {
                return;
            }
            this.$emit('submit', this.form);

            if (this.editMode) {
                this.initialValues = {...this.form};
            }

            return this.form;
        },
    },
    beforeMount() {
        if (this.editMode) {
            this.initialValues = {
                ...this.value,
                role: this.value.role.roleId,
                businessUnits: this.value.businessUnits.map((unit) => unit.businessUnitId),
            };
        }
        if (this.value) {
            this.form = {
                ...defaultFormValues,
                ...this.value,
                role: this.value.role.roleId,
                businessUnits: this.value.businessUnits.map((unit) => unit.businessUnitId),
            };
        } else {
            this.form = {...defaultFormValues};
        }
    },
};
</script>
